<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
// Main layout components

export default {
  name: 'it',
  modules: [
    'userProfile'
  ]
}
</script>
